$(document).foundation();

$(document).ready(function () {
    // utils
    initVerticalCenter();
    initAnimateInView();
    inlineSVGs([
        '.footer__social img'
    ]);

    // global
    initOffCanvas();
	initHeader();
    initSliders();
	initVideoPanels();
	triggerPopup();
	initCookieWarning();

    // template specific
	initAccordions();
	initEventLoadMore();
	initNews();
	uploadProfilePic();
	initGallery();
});

function initCookieWarning() {
    if (Cookies.get('mih_accepted') !== undefined) {
        return;
    }

    $('.cookie button').on('click', function () {
        Cookies.set('mih_accepted', 'cookie warning accepted', { expires: 1 });
        console.log("button clicked");
        $(this)
            .closest('.cookie')
            .slideUp();
            $('.hero-booking-widget').removeClass('cookie-on');
            $('.scroll').removeClass('cookie-on');
    });

    $('.cookie').slideDown();
    $('.hero-booking-widget').addClass('cookie-on');
    $('.scroll').addClass('cookie-on');

	$('#popup .close-button').click(function() {
		$('#popup').foundation('reveal', 'close');
	});
}

function triggerPopup() {
	if ($('#popup').length > 0) {
		setTimeout(function() {
			$('#popup').foundation('open');
		}, 2500)
	}
}


function uploadProfilePic() {
	$('#profile-pic').change(function(evt) {
		console.log($(this).val());
	});

	$('.edit-profile__form-form form').on('submit', function () {
		// Remove the desktop profile pic field if on mobile...
		if (Foundation.MediaQuery.current === 'small') {
			$('.edit-profile__picture.hide-for-small-only').remove();
		} else {
			// ...and remove the mobile one if on everything else.
			$('.edit-profile__picture.show-for-small-only').remove();
		}
	});
}

function initEventLoadMore() {
	$('#events-load-more').click(function() {
		$.ajax({
			url: $(this).data("url"),
			success : function(html){
				if(html){
					$('#events-load-more').remove();
					$(".conferences-events").append(html);
					initEventLoadMore();
				}
			}
		});
		$(this).hide();
	})
}



function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === "object") {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
	        $.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = $(data).find('svg');

	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }

	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');

	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }

	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}





function initHeader() {

}






function initOffCanvas() {
	$('.header__off-canvas-button').on("click", function() {
		$(this).css("display", "none");
		$('.header__off-canvas-close').css("display", "block");
	});

	$('.header__off-canvas-close').on("click", function() {
		$(this).css("display", "none");
		$('.header__off-canvas-button').css("display", "block");
	});
}






/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}







function initSliders() {

	$('.slider__inner').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: true
	});

	// If there's no slider, make sure that the content starts below the header
	if ($('.slider').length === 0) {
		pushContentBelowHeader();
		$(window).on('resize', pushContentBelowHeader);
	} else if (Foundation.MediaQuery.is('small only')) {
		pushContentBelowHeader();
		$(window).on('resize', pushContentBelowHeader);
	}

	// Push all non-full height sliders below the header
	if (($('.slider').length == 1) && (!$('.slider').hasClass('slider--full-height'))) {
		pushContentBelowHeader();
		$(window).on('resize', pushContentBelowHeader);
	}

	$('.home-events__carousel').slick({
		arrows: true,
		fade: true,
		prevArrow: '<button type="button" class="slick-prev">Previous <img src="/assets/img/right-chevron-grey.svg"></button>',
		nextArrow: '<button type="button" class="slick-next">Next <img src="/assets/img/right-chevron-grey.svg"></button>'
	});


	$('.home-news__carousel').on('init', function (event, slick) {
		// Align the arrows of this carousel to the image, not the whole item
		Foundation.onImagesLoaded($('.home-news__item-image img'), function () {
			$('.home-news .slick-arrow').css('top', $('.home-news__item-image').outerHeight() / 2);
		});
	});

	$('.home-news__carousel').slick({
		arrows: true,
		slidesToShow: 3,
		prevArrow: '<button type="button" class="slick-prev">Previous <img src="/assets/img/right-chevron-grey.svg"></button>',
		nextArrow: '<button type="button" class="slick-next">Next <img src="/assets/img/right-chevron-grey.svg"></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	Foundation.reInit('equalizer');







	$('.committee__carousel').slick({
		arrows: true,
		slidesToShow: 4,
		prevArrow: '<button type="button" class="slick-prev">Previous <img src="/assets/img/right-chevron-grey.svg"></button>',
		nextArrow: '<button type="button" class="slick-next">Next <img src="/assets/img/right-chevron-grey.svg"></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});




	$('.intro-slider__carousel-inner').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: true
	});


	$('.ticket-slider__carousel-inner').slick({
		arrows: true,
		pagination: false,
		fade: true,
		prevArrow: '<button type="button" class="slick-arrow slick-prev">Prev</button>',
		nextArrow: '<button type="button" class="slick-arrow slick-next">Next</button>'
	});


	$('.quotes__carousel').slick({
		arrows: true,
		fade: true,
		prevArrow: '<button type="button" class="slick-prev">Previous <img src="/assets/img/right-chevron-grey.svg"></button>',
		nextArrow: '<button type="button" class="slick-next">Next <img src="/assets/img/right-chevron-grey.svg"></button>'
	});

	$('.featured-event-slider__carousel').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: false
	});

	$('.conference-ticket-slider__carousel').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: false
	});


	inlineSVGs('.slick-arrow img');
}

function pushContentBelowHeader() {
	$('main').css('padding-top', $('.header').outerHeight());
}

function spinMe() {
	$('body').toggleClass('spin');
}


function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
	    rect.top <=  $(window).scrollTop() + window.innerHeight
    );
}





function initVideoPanels() {
	var $modal;
	var modal = '<div id="video-modal" class="gallery-modal gallery-modal--video small reveal" data-reveal><button class="gallery-modal__close close-button" data-close aria-label="Close modal" type="button"></button><div class="gallery-modal__iframe"><iframe allowfullscreen frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';

	$('a[href*="youtube.com"], a[href*="youtu.be"], a[href*="vimeo.com"]').addClass('is-video').on('click', function () {

		$modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );

		$('body').append($modal);

		$('#video-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });

		return false;
	});
}


function generateVideoEmbedUrl(videoUrl) {
	if (videoUrl.indexOf('youtube.com/embed') > -1) {
		// already a YouTube embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('youtu') > -1) {
		// must be either a full or shortened YouTube url so parse and create embed link
	    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	    var match = videoUrl.match(regExp);

	    if (match && match[2].length == 11) {
	        return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
	    } else {
	        return videoUrl;
	    }
	} else if (videoUrl.indexOf('player.vimeo.com') > -1) {
		// already a Vimeo embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
		// normal Vimeo link, so generate and embed code
		return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
	}

	// if all else fails, return the original link
	return embedUrl;
}



function initAccordions() {
	$('.tabs-content').accordiom();

	$('.off-canvas__primary-nav').accordiom();

	$('.members__filters').accordiom({
		showFirstItem: false
	});
}




function initNews() {
	var $_filterButtons = $('.news__filters button');
	var $_filterItems = $('.news__item');

	$_filterButtons.on('click', function() {
		$('.news__filters button.on').removeClass('on');
		$(this).addClass('on');

		// Clear the "All" button
		if (!$(this).hasClass('all')) {
			$('.news__filters button.all').removeClass('on');
		} else {
			// All button has been clicked, so show all buttons and return
			$('.news__filters button').removeClass('on');
			$('.news__filters button.all').addClass('on');
			$_filterItems.fadeIn();
			return;
		}

		var currentCats = '';

		// Loop through the "on" buttons and build a class selector string of the active
		$_filterButtons.each(function() {
			if ($(this).hasClass('on')) {
				currentCats += '.' + $(this).data('category') + ', ';
			}
		});
		currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

		// No categories selected so show them all
		if (currentCats == '') {
			$_filterItems.fadeIn();
		} else {
			// Hide or show the elements that match the class
			$_filterItems.each(function() {
				if ($(this).is(currentCats)) {
					$(this).fadeIn();
				} else {
					$(this).fadeOut();
				}
			});
        }
    });

    // Pre-filter the offers
    // Get the url parameters
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function(s) {
        	return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.search.substring(1);

        while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

        if (urlParams['category']) {
        		$('.news__filters button[data-category="' + urlParams['category'] + '"]').trigger('click');
        }


    // Trigger the correct filter buttons when the mobile filter dropdown is used
	$('.news__filters-mobile-selecter').on('change', function () {
		$('.news__filters button.on').removeClass('on');
		$('.news__filters button[data-category="' + $(this).val() + '"]').trigger('click');
	});
}

function initGallery() {

	// Gallery triggers
	$('a[href="#open-gallery"]').on('click', function () {
		$('.accommodation-gallery').show();
		$('html, body').animate({
			scrollTop: 0
		}, 0);
		return false;
	});

	// $('.accommodation-gallery .close-button').on('click', function () {
	// 	$('.accommodation-gallery').hide();
	// });


	$('.gallery-grid .wrap a').fancybox({
		type: 'ajax',
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
		autoSize: false,
		mouseWheel: false,
		arrows: false,
		nextEffect: 'fade',
		prevEffect: 'fade',
		wrapCSS: 'gallery-popup'
	});


  $('.gallery-grid-item__cta a').fancybox({
		type: 'ajax',
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
		autoSize: false,
		mouseWheel: false,
		arrows: false,
		nextEffect: 'fade',
		prevEffect: 'fade',
		wrapCSS: 'gallery-popup'
	});

	$('.gallery-grid a').on('click')

	var count = 0;
	$('.gallery-grid-item, .gallery-grid__playlist-heading').each( function () {
		$(this).data('number', count);
		count++;
	});

	function sortDefault(selector) {
		$(selector).children('.gallery-grid-item, .gallery-grid__playlist-heading').sort(function(a, b) {
			var A = $(a).data('number');
			var B = $(b).data('number');
			return (A < B) ? -1 : (A > B) ? 1 : 0;
		}).appendTo(selector);
	}

	function sortAZ(selector) {
		$(selector).children('.gallery-grid-item').sort(function(a, b) {
			var A = $(a).find('.gallery-grid-item__caption').text().toUpperCase();
			var B = $(b).find('.gallery-grid-item__caption').text().toUpperCase();
			return (A < B) ? -1 : (A > B) ? 1 : 0;
		}).appendTo(selector);
	}

	function sortZA(selector) {
		$(selector).children('.gallery-grid-item').sort(function(a, b) {
			var A = $(a).find('.gallery-grid-item__caption').text().toUpperCase();
			var B = $(b).find('.gallery-grid-item__caption').text().toUpperCase();
			return (A > B) ? -1 : (A < B) ? 1 : 0;
		}).appendTo(selector);
	}

	$('#video-dropdown').on('change', function () {
		if ($(this).val()=='A-Z') {
			sortAZ('#video-tab .gallery-grid');
		} else if ($(this).val()=='Z-A') {
			sortZA('#video-tab .gallery-grid');
		} else {
			sortDefault('#video-tab .gallery-grid');
		}

		Foundation.reInit('equalizer');
	})

	// function sortOld(selector) {
	// 	$(selector).children('.gallery-grid-item').sort(function(a, b) {
	// 		var A = new Date($(a).find('.gallery-grid-item__date').data('date'));
	// 		var B = new Date($(b).find('.gallery-grid-item__date').data('date'));
	// 		return (A < B) ? -1 : (A > B) ? 1 : 0;
	// 	}).appendTo(selector);
	// }
  //
	// function sortNew(selector) {
	// 	$(selector).children('.gallery-grid-item').sort(function(a, b) {
	// 		var A = new Date($(a).find('.gallery-grid-item__date').data('date'));
	// 		var B = new Date($(b).find('.gallery-grid-item__date').data('date'));
	// 		return (A > B) ? -1 : (A < B) ? 1 : 0;
	// 	}).appendTo(selector);
	// }
  //
	// $('#video-dropdown').on('change', function () {
	// 	if ($(this).val()=='new') {
	// 		$('.gallery-grid__playlist-heading').hide();
	// 		sortNew('#video-tab .gallery-grid');
	// 	} else if ($(this).val()=='old') {
	// 		$('.gallery-grid__playlist-heading').hide();
	// 		sortOld('#video-tab .gallery-grid');
	// 	} else {
	// 		$('.gallery-grid__playlist-heading').show();
	// 		sortDefault('#video-tab .gallery-grid');
	// 	}
	// 	Foundation.reInit('equalizer');
	// })

	/*** Filterable Gallery ***/
	var $_filterButtons = $('.gallery-filters button');
	var $_filterItems = $('#video-tab .gallery-grid-item');

	$('.gallery-filters button').on('click', function () {

		if ( $(this).hasClass('on') ) return; // already selected so do nothing

		$('.gallery-filters button').removeClass('on');
		$(this).addClass('on');

		$('.gallery-grid-item.column.center-this').removeClass('center-this');

		if ( $(this).hasClass('all') ) {
			$('#video-tab .gallery-grid').removeClass('filtered');
			$_filterItems.fadeIn().children('a').attr('data-fancybox-group', 'gallery');
			if (history.pushState) {
				history.pushState('', document.title, window.location.pathname + window.location.search);
			}
			else {
				location.hash = '#';
			}
			return;
		} else {
			$('#video-tab .gallery-grid').addClass('filtered');
		}

		// Set the window hash URL to the current category
		if (history.pushState) {
			history.pushState(null, null, '#' + $(this).data('filter'));
		}
		else {
			location.hash = '#' + $(this).data('filter');
		}

		var currentCats = '';

		// Loop through the "on" buttons and build a class selector string of the active
		$_filterButtons.each( function () {
			if ( $(this).hasClass('on') ) {
				currentCats += '.' + $(this).data('filter') + ', ';
			}
		});
		currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

		// No categories selected so show them all
		/*if ( currentCats == '' ) {
			$_filterItems.fadeIn();
			return;
		}*/

		// Hide or show the elements that match the class
		$_filterItems.each( function () {
			if ( $(this).is(currentCats) ) {
				$(this).fadeIn().children('a').attr('data-fancybox-group', 'gallery');
			} else {
				$(this).fadeOut().children('a').removeData('fancybox-group').removeAttr('data-fancybox-group');
			}
		});

		setTimeout(centerGalleryWidows, 500);

	});





	/*** Filter the gallery video playlists ***/
	$('.gallery-playlist-filters button').on('click', function () {
		$filterButton = $(this);

		if ( $filterButton.hasClass('on') ) return;

		$('.gallery-playlist-filters button.on').removeClass('on');
		$filterButton.addClass('on');

		if ( $filterButton.hasClass('all') ) {
			// Show all playlists
			$('.gallery-grid__playlist-heading, .gallery-grid-item-video').fadeIn();
		} else {
			$('.gallery-grid__playlist-heading').fadeOut();

			// Only show the playlists which match
			$('.gallery-grid-item-video').each(function () {
				if ( $(this).data('playlist') === $filterButton.text() ) {
					$(this).fadeIn();
				} else {
					$(this).fadeOut();
				}
			});
		}
	});




	// Allow pre-filtering with hash URLs
	if ( window.location.hash != '' ) {
		var hashCat = window.location.hash.replace('#', '');
		$('.gallery-filters button[data-filter="' + hashCat + '"]').trigger('click');
	}



	// Calculate the remainder gallery items that don't fit on the row and center them
	function centerGalleryWidows() {

		var numWidows = 0;

		if ( $(window).innerWidth() > 1024 ) { // 3 column large layout
			numWidows = $('.gallery-grid-item:visible').length % 3;
		} else if ( $(window).innerWidth() > 1024 ) { // 2 column tablet layout
			numWidows = $('.gallery-grid-item:visible').length % 2;
		} else {
			return; // must be mobile so do nothing
		}

		if ( numWidows > 0 ) {
			$('.gallery-grid-item:visible').slice( numWidows * -1 ).addClass('center-this');
		}

	}



	// Center widows on the grids
	$('.grid-2-column').each( function() {
		if ( $(this).children().length === 1 ) {
			$(this).children('.medium-6').addClass('medium-centered');
		}
	});

}
